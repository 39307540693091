import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import '@fortawesome/fontawesome-free/css/all.css';

import { Offcanvas } from 'react-bootstrap';

import ModalUI from "../components/common/ModalUI";
import EngagementForm from "../components/forms/EngagementForm";
import Discipline from "../components/statics/activites/Discipline";
import axiosService from "../services/axiosService";
import { formatEventTimes } from "../services/commonService";

import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from "react-image-gallery";

function WebApp() {
  // Articles
  const [articles, setArticles] = useState(null);
  // Loading
  const [loading, setLoading] = useState(true);
  // Modal
  const [show, setShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalPlus, setModalPlus] = useState('');
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (title, plus = null) => {
    setModalPlus(plus)
    setModalTitle(title);
    setShow(true);
  };
  // Comite
  const [comites, setComites] = useState([]);
  const [comitesDirectionTab, setComitesDirectionTab] = useState(true);
  const [comitesSecretariatTab, setComitesSecretariatTab] = useState(false);
  const [comitesTresoriersTab, setComitesTresoriersTab] = useState(false);
  const [comitesMembresTab, setComitesMembresTab] = useState(false);
  // Disciplines
  const [disciplines, setDisciplines] = useState([]);
  // Evenements
  const [evenementsPassesTab, setEvenementsPassesTab] = useState(false);
  const [evenementsProchainsTab, setEvenementsProchainsTab] = useState(true);
  const [evenementsPasses, setEvenementsPasses] = useState(null);
  const [evenementsProchains, setEvenementsProchains] = useState(null);
  const optionsDate = { year: 'numeric', month: 'numeric', day: 'numeric' };
  // Contacts
  const [nousEcrireTab, setSousEcrireTab] = useState(true);
  const [nousSituerTab, setNousSituerTab] = useState(false);
  // Medias
  const [courses, setCourses] = useState([]);
  const [animations, setAnimations] = useState([]);
  const [expositions, setExpositions] = useState([]);
  const [restauration, setRestauration] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  // Medias tabs
  const [coursesTab, setCoursesTab] = useState(false);
  const [animationsTab, setAnimationsTab] = useState(false);
  const [expositionsTab, setExpositionsTab] = useState(false);
  const [restaurationTab, setRestaurationTab] = useState(false);
  const [sponsorsTab, setSponsorsTab] = useState(false);
  // Videos
  const [videos, setVideos] = useState([]);
  // Medias togglers
  const [togglerVideos, setTogglerVideos] = useState(false);
  // Media : active
  const [activeCourses, setActiveCourses] = useState('');
  const [activeAnimations, setActiveAnimations] = useState('');
  const [activeExpositions, setActiveExpositions] = useState('');
  const [activeRestauration, setActiveRestauration] = useState('');
  const [activeSponsors, setActiveSponsors] = useState('');

  // Return to top
  const [returnTop, setReturnTop] = useState(false);
  // Loading Map
  const [loadingContactMap, setLoadingContactMap] = useState(true);

  const showHideComiteTabs = (tab) => {
    setComitesDirectionTab(false);
    setComitesSecretariatTab(false);
    setComitesTresoriersTab(false);
    setComitesMembresTab(false);

    switch (tab) {
      case 'ComiteDirection' :
        setComitesDirectionTab(true);
        break;
      case 'ComiteSecretariat' :
        setComitesSecretariatTab(true);
        break;
      case 'ComiteTresoriers' :
        setComitesTresoriersTab(true);
        break;
      case 'ComiteMembres' :
        setComitesMembresTab(true);
        break;
    }
  }

  const showHideEvenementsTabs = (tab) => {
    setEvenementsPassesTab(false);
    setEvenementsProchainsTab(false);

    switch (tab) {
      case 'EvenementsPasses' :
        setEvenementsPassesTab(true);
        break;
      case 'EvenementsProchains' :
        setEvenementsProchainsTab(true);
        break;
    }
  }

  const showHideContactTabs = (tab) => {
    setSousEcrireTab(true);
    setNousSituerTab(false);

    switch (tab) {
      case 'NousEcrire' :
        setSousEcrireTab(true);
        break;
      case 'NousSituer' :
        setNousSituerTab(true);
        setTimeout(() => {
            setLoadingContactMap(false);
        }, 3000);
        break;
    }
  }

  const showHidePhotosTabs = (tab) => {
    setCoursesTab(false);
    setAnimationsTab(false);
    setExpositionsTab(false);
    setRestaurationTab(false);
    setSponsorsTab(false);

    switch (tab) {
      case 'Courses' :
        setCoursesTab(true);
        break;
      case 'Animations' :
        setAnimationsTab(true);
        break;
      case 'Expositions' :
        setExpositionsTab(true);
        break;
      case 'Restauration' :
        setRestaurationTab(true);
        break;
      case 'Sponsors' :
        setSponsorsTab(true);
        break;
    }
  }  

  const toggleVisibility = () => {
    if (window.scrollY > 200) {
      setReturnTop(true);
    } else {
      setReturnTop(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0)
    // scroll.scrollToTop({
    //   duration: 500,
    //   smooth: true,
    // });
  };

  const getCategorieSeverity = (eventCategory) => {
    switch (eventCategory) {
      case 'Cours':
        return 'bg-warning';
      case 'Course':
        return 'bg-info';
      case 'Galop':
        return 'bg-secondary';
      case 'Trot':
        return 'bg-success';
      default:
        return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosService.get('/front');
        setArticles(response.data.articles);
        setDisciplines(response.data.disciplines);
        const eventsPassed = response.data.evenementsPasses;
        const eventsNext = response.data.evenementsProchains;
        setEvenementsPasses(eventsPassed);
        setEvenementsProchains(eventsNext);
        if (eventsPassed && eventsPassed.length > 0 && (!eventsNext || eventsNext.length === 0)) {
          showHideEvenementsTabs('EvenementsPasses')
        }
        setComites(response.data.comites);
        const filteredCourses = response.data.photos.filter(photo => photo.categorie === 'Courses').map(photo => ({
            ...photo,
            original: `${process.env.REACT_APP_BACKEND_URL}/uploads/photos/Courses/${photo.chemin}`,
            thumbnail: `${process.env.REACT_APP_BACKEND_URL}/uploads/photos/Courses/${photo.chemin}`,
            ...(photo.legende && { description: `${photo.legende}` })
        }));
        setCourses(filteredCourses);
        let tabActived = false;
        if (filteredCourses.length > 0) {
          setCoursesTab(true);
          setActiveCourses('active');
          tabActived = true;
        }
        const filteredAnimations = response.data.photos.filter(photo => photo.categorie === 'Animations').map(photo => ({
            ...photo,
            original: `${process.env.REACT_APP_BACKEND_URL}/uploads/photos/Animations/${photo.chemin}`,
            thumbnail: `${process.env.REACT_APP_BACKEND_URL}/uploads/photos/Animations/${photo.chemin}`,
            ...(photo.legende && { description: `${photo.legende}` })
        }));
        setAnimations(filteredAnimations);
        if (filteredAnimations.length > 0 && !tabActived) {
          setAnimationsTab(true);
          setActiveAnimations('active');
          tabActived = true;
        }
        const filteredExpositions = response.data.photos.filter(photo => photo.categorie === 'Expositions').map(photo => ({
            ...photo,
            original: `${process.env.REACT_APP_BACKEND_URL}/uploads/photos/Expositions/${photo.chemin}`,
            thumbnail: `${process.env.REACT_APP_BACKEND_URL}/uploads/photos/Expositions/${photo.chemin}`,
            ...(photo.legende && { description: `${photo.legende}` })
        }));
        setExpositions(filteredExpositions);
        if (filteredExpositions.length > 0 && !tabActived) {
          setExpositionsTab(true);
          setActiveExpositions('active');
          tabActived = true;
        }
        const filteredRestauration = response.data.photos.filter(photo => photo.categorie === 'Restauration').map(photo => ({
            ...photo,
            original: `${process.env.REACT_APP_BACKEND_URL}/uploads/photos/Restauration/${photo.chemin}`,
            thumbnail: `${process.env.REACT_APP_BACKEND_URL}/uploads/photos/Restauration/${photo.chemin}`,
            ...(photo.legende && { description: `${photo.legende}` })
        }));
        setRestauration(filteredRestauration);
        if (filteredRestauration.length > 0 && !tabActived) {
          setRestaurationTab(true);
          setActiveRestauration('active');
          tabActived = true;
        }
        const filteredSponsors = response.data.photos.filter(photo => photo.categorie === 'Sponsors').map(photo => ({
          ...photo,
          original: `${process.env.REACT_APP_BACKEND_URL}/uploads/photos/Sponsors/${photo.chemin}`,
          thumbnail: `${process.env.REACT_APP_BACKEND_URL}/uploads/photos/Sponsors/${photo.chemin}`,
          ...(photo.legende && { description: `${photo.legende}` })
        }));
        setSponsors(filteredSponsors);
        if (filteredSponsors.length > 0 && !tabActived) {
          setSponsorsTab(true);
          setActiveSponsors('active');
          tabActived = true;
        }
        setVideos(response.data.videos);
      } catch (error) {
        alert('Erreur de chargement de contenu ...')
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    if (evenementsPasses && evenementsPasses.length > 0 && (!evenementsProchains || evenementsProchains.length === 0)) {
      showHideEvenementsTabs('EvenementsPasses')
    }
  }, []);

  useEffect(() => {
    setTogglerVideos(false);

    // Return to Top
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  const showVideosToggler = () => {
    setTogglerVideos(!togglerVideos);
  }

  const showNousEcrire = () => {
    setLoadingContactMap(false);
    showHideContactTabs('NousEcrire');
  }

  const dashboardUrl = process.env.REACT_APP_DASHBOARD_URL;

  return (
    <>
      <main>

        <nav className="navbar navbar-expand-lg bg-dark">
            <div className="container">
                <a className="navbar-brand" href="index.html" title="Accueil">
                    <i className="fas fa-horse-head"></i> CHR <span style={{ fontSize: 40 }}>44</span>
                </a>

                <a className="btn custom-btn-maroon d-lg-none ms-auto me-4" href={dashboardUrl} target="_blank"><i className="fas fa-lock"></i> Admin</a>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav align-items-lg-center ms-auto me-lg-5">

                        <li className="nav-item">
                            <a className="nav-link click-scroll p-1" href="#section_2"><i className="fas fa-university"></i> L'association</a>
                        </li>
                        
                        <li className="nav-item">
                            <a className="nav-link click-scroll p-1" href="#section_4"><i className="fas fa-calendar"></i> Evènements</a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link click-scroll p-1" href="#section_3"><i className="fas fa-horse"></i> Disciplines</a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link click-scroll p-1" href="#section_5"><i className="fas fa-film"></i> Médias</a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link click-scroll p-1" href="#section_6"><i className="fas fa-edit"></i> Contact</a>
                        </li>
                    </ul>

                    <a className="btn custom-btn-maroon d-lg-block d-none" href={dashboardUrl} target="_blank"><i className="fas fa-lock"></i> Admin</a>
                </div>
            </div>
        </nav>

        {/************** Modal ***************/}
        <Offcanvas show={show} onHide={handleClose} >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title style={{ color: '#7D4427' }}>{modalTitle}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {modalTitle === 'Engagement' && <EngagementForm />}
                {modalTitle !== 'Engagement' && <Discipline plus={modalPlus} />}
            </Offcanvas.Body>
        </Offcanvas>

        {/************ Home Page *************/}
        <section className="hero-section" id="section_1">
            <div className="section-overlay"></div>

            <div className="containerCircle">
                <div className="circleContent">
                    <div className="imageCircle imageCircle1"></div>
                    <p className="captionCircle bg-default">Trot attelé</p>
                </div>
                <div className="circleContent">
                    <div className="imageCircle imageCircle2"></div>
                    <p className="captionCircle bg-default">Trot monté</p>
                </div>
                <div className="circleContent">
                    <div className="imageCircle imageCircle3"></div>
                    <p className="captionCircle bg-default">Galop</p>
                </div>
            </div>

            <div className="container d-flex justify-content-center align-items-center">
                <div className="row">

                    <div className="col-12 mt-auto mb-5 text-center">
                        <small>Fédération Départementale Des Courses Hippiques Rurales De Loire Atlantique</small>

                        <h1 className="text-white mb-5">Courses Hippiques Rurales</h1>

                        <a className="btn custom-btn smoothscroll" onClick={() => handleShow('Engagement')}><i className="fas fa-edit"></i> Nous rejoindre</a>
                    </div>

                    <div className="col-lg-12 col-12 mt-auto flex-column flex-lg-row text-center">
                        {/*<div className="date-wrap">
                            <h5 className="text-white">
                                <a href="#section_4" title="Prochains évènements">
                                    <i className="custom-icon fas fa-calendar me-2"></i>
                                </a>
                                Du 15 au 17 <sup>inclus</sup>, Mai 2024
                            </h5>
                        </div>

                        <div className="location-wrap mx-auto py-3 py-lg-0">
                            <h5 className="text-white">
                                <i className="custom-icon bi-geo-alt me-2"></i>
                                23 rue de la grande maison 44119, Treillières
                            </h5>
                        </div>*/}

                        <div className="social-share">
                            <ul className="social-icon d-flex align-items-center justify-content-center">
                                <span className="text-white me-3">Retrouvez-nous sur</span>

                                <li className="social-icon-item">
                                    <a href="#" className="social-icon-link" title="Facebook">
                                        <span className="bi-facebook"></span>
                                    </a>
                                </li>

                                {/* <li className="social-icon-item">
                                    <a href="#" className="social-icon-link">
                                        <span className="bi-twitter"></span>
                                    </a>
                                </li> */}

                                <li className="social-icon-item">
                                    <a href="#" className="social-icon-link" title="Tiktok">
                                        <span className="bi-tiktok"></span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        {/************ Association *************/}
        <section className="about-section section-padding" id="section_2">
            <div className="container">
                <div className="row justify-content-center">

                    <div className="col-12 text-center">
                        <h2 className="text-white mb-4"><i className="fas fa-university"></i> L'association</h2>
                    </div>

                    <div className="col-lg-6 col-12">
                        <div className="about-text-wrap p-2">

                            <div className="about-text-info d-flex p-2" style={{ color: '#7D4427' }}>
                                <div className="d-flex p-auto">
                                    <i className="fa-solid fa-people-group" style={{ fontSize: 32, marginLeft: 15, marginRight: -8 }}></i>
                                </div>
                                <div className="ms-4">
                                    <h3>Refondée en mars 2024</h3>
                                </div>
                            </div>

                            <img src="./festava-live/images/animaux/trolls.jpeg" className="about-image img-fluid bg-success p-1"  />
                        </div>
                    </div>

                    <div className="col-12 mb-4 mb-lg-0 d-flex align-items-center">
                        {loading ? <p>Chargement...</p> :
                        <div className="services-info">
                            {articles.map((article, index) => (
                                article.type === 'Association' &&
                                    <p key={index} >
                                        <h6 className="text-white mt-4">{article.titre}</h6>
                                        <p className="text-white dynamicHtml" dangerouslySetInnerHTML={{ __html: article.contenu }} />
                                    </p>
                            ))}
                        </div>}
                    </div>

                </div>

                <div className="row">

                    <div className="col-xl-5 col-lg-6 col-md-10 col-sm-12 col-xs-12 mx-auto">
                        <h3 className="text-center m-4 text-white">Bureau administrateur</h3>

                        <nav className="d-flex justify-content-center">
                            <div className="nav nav-tabs align-items-baseline justify-content-center bg-success p-1" id="nav-tab"
                                role="tablist">
                                <button className="nav-link active p-2" id="direction-tab" data-bs-toggle="tab" style={{ margin: 2 }}
                                    data-bs-target="#nav-direction" type="button" role="tab"
                                    aria-controls="nav-direction" aria-selected="false" onClick={() => showHideComiteTabs('ComiteDirection')}>
                                    <h7>Direction</h7>
                                </button>

                                <button className="nav-link p-2" id="secretariat-tab" data-bs-toggle="tab" style={{ margin: 2 }}
                                    data-bs-target="#nav-secretariat" type="button" role="tab"
                                    aria-controls="nav-secretariat" aria-selected="false" onClick={() => showHideComiteTabs('ComiteSecretariat')}>
                                    <h7>Secrétariat</h7>
                                </button>

                                <button className="nav-link p-2" id="tresoriers-tab" data-bs-toggle="tab"
                                    data-bs-target="#nav-tresoriers" type="button" role="tab"
                                    aria-controls="nav-tresoriers" aria-selected="false" onClick={() => showHideComiteTabs('ComiteTresoriers')}>
                                    <h7>Trésoriers</h7>
                                </button>

                                <button className="nav-link p-2" id="membres-tab" data-bs-toggle="tab"
                                    data-bs-target="#nav-membres" type="button" role="tab"
                                    aria-controls="nav-membres" aria-selected="false" onClick={() => showHideComiteTabs('ComiteMembres')}>
                                    <h7>Membres</h7>
                                </button>
                            </div>
                        </nav>

                        {/* Direction */}
                        { comitesDirectionTab &&
                          <div className="tab-content shadow-lg mt-5 p-1" id="nav-tabContent" style={{ backgroundColor: 'transparent', border: 2, borderColor: '#A2C523', borderStyle: 'solid', color: '#A2C523' }}>
                            <div className="tab-pane fade show active" id="nav-direction" role="tabpanel" aria-labelledby="direction-tab">                                
                                
                                <div id="carouselExampleControls" className="carousel carousel-dark slide" data-bs-ride="carousel">
                                    <div className="carousel-inner shadow-lg"  style={{ borderRadius: 15, height: 650 }}>
                                        {comites.filter(comite => comite.type === 'Direction').map((admin, key) => 
                                            <div className={`carousel-item ${0 === key && 'active'} shadow-lg`}>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="card" style={{ width: '100%' }}>
                                                            {admin.chemin ? 
                                                                <img src={`${process.env.REACT_APP_BACKEND_URL}/uploads/comite/${admin.type}/${admin.chemin}`} className="card-img-top" alt="..." />
                                                            :
                                                                <img src="./festava-live/images/default/default.jpg" className="card-img-top" alt="..." />
                                                            }
                                                            <div className="card-body mt-3" style={{ minHeight: 180 }}>
                                                                <h5 className="card-title">{admin.nom}</h5>
                                                                <p className="card-text" style={{ fontSize: 15 }}>
                                                                    <b>Email</b> : {admin.email ?? 'non renseigné'} <br />
                                                                    <b>Tél</b> : {admin.telephone ?? 'non renseigné'}
                                                                </p>
                                                                <a href={admin.lien} className="btn custom-btn" target="_blank">Contacter</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev" style={{ marginTop: 10 }}>
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>

                                </div>

                            </div>
                          </div>
                        }

                        {/* Secrétariat */}
                        { comitesSecretariatTab &&
                          <div className="tab-content shadow-lg mt-5 p-1" id="nav-tabContent" style={{ backgroundColor: 'transparent', border: 2, borderColor: '#A2C523', borderStyle: 'solid', color: '#A2C523' }}>
                            <div className="tab-pane fade show active" id="nav-secretariat" role="tabpanel"
                                aria-labelledby="secretariat-tab">                                
                                
                                <div id="carouselExampleControlsAdjoints" className="carousel carousel-dark slide" data-bs-ride="carousel">
                                    <div className="carousel-inner shadow-lg"  style={{ borderRadius: 15, height: 650 }}>
                                        {comites.filter(comite => comite.type === 'Secretariat').map((adjoint, key) => 
                                            <div className={`carousel-item ${0 === key && 'active'} shadow-lg`}>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="card" style={{ width: '100%' }}>
                                                            {adjoint.chemin ? 
                                                                <img src={`${process.env.REACT_APP_BACKEND_URL}/uploads/comite/${adjoint.type}/${adjoint.chemin}`} className="card-img-top" alt="..." />
                                                            :
                                                                <img src="./festava-live/images/default/default.jpg" className="card-img-top" alt="..." />
                                                            }
                                                            <div className="card-body mt-3" style={{ minHeight: 180 }}>
                                                                <h5 className="card-title">{adjoint.nom}</h5>
                                                                <p className="card-text" style={{ fontSize: 15 }}>
                                                                    <b>Email</b> : {adjoint.email ?? 'non renseigné'} <br />
                                                                    <b>Tél</b> : {adjoint.telephone ?? 'non renseigné'}
                                                                </p>
                                                                <a href={adjoint.lien} className="btn custom-btn" target="_blank">Contacter</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControlsAdjoints" data-bs-slide="prev" style={{ marginTop: 10 }}>
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControlsAdjoints" data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>

                                </div>

                            </div>
                          </div>
                        }

                        {/* Trésoriers */}
                        { comitesTresoriersTab &&
                          <div className="tab-content shadow-lg mt-5 p-1" id="nav-tabContent" style={{ backgroundColor: 'transparent', border: 2, borderColor: '#A2C523', borderStyle: 'solid', color: '#A2C523' }}>
                            <div className="tab-pane fade show active" id="nav-tresoriers" role="tabpanel"
                                aria-labelledby="tresoriers-tab">

                                <div id="carouselExampleControls" className="carousel carousel-dark slide" data-bs-ride="carousel">
                                    <div className="carousel-inner shadow-lg"  style={{ borderRadius: 15, height: 650 }}>
                                        {comites.filter(comite => comite.type === 'Tresoriers').map((membre, key) => 
                                            <div className={`carousel-item ${0 === key && 'active'} shadow-lg`}>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="card" style={{ width: '100%' }}>
                                                            {membre.chemin ? 
                                                                <img src={`${process.env.REACT_APP_BACKEND_URL}/uploads/comite/${membre.type}/${membre.chemin}`} className="card-img-top" alt="..." />
                                                            :
                                                                <img src="./festava-live/images/default/default.jpg" className="card-img-top" alt="..." />
                                                            }
                                                            <div className="card-body mt-3" style={{ minHeight: 180 }}>
                                                                <h5 className="card-title">{membre.nom}</h5>
                                                                <p className="card-text" style={{ fontSize: 15 }}>
                                                                    <b>Email</b> : {membre.email ?? 'non renseigné'} <br />
                                                                    <b>Tél</b> : {membre.telephone ?? 'non renseigné'}
                                                                </p>
                                                                <a href={membre.lien} className="btn custom-btn" target="_blank">Contacter</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev" style={{ marginTop: 10 }}>
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>

                                  </div>

                              </div>
                          </div>
                        }
                        
                        {/* Membres */}
                        { comitesMembresTab &&
                          <div className="tab-content shadow-lg mt-5 p-1" id="nav-tabContent" style={{ backgroundColor: 'transparent', border: 2, borderColor: '#A2C523', borderStyle: 'solid', color: '#A2C523' }}>
                            <div className="tab-pane fade show active" id="nav-membres" role="tabpanel"
                                aria-labelledby="membres-tab">

                                <div id="carouselExampleControls" className="carousel carousel-dark slide" data-bs-ride="carousel">
                                    <div className="carousel-inner shadow-lg"  style={{ borderRadius: 15, height: 650 }}>
                                        {comites.filter(comite => comite.type === 'Membres').map((membre, key) => 
                                            <div className={`carousel-item ${0 === key && 'active'} shadow-lg`}>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="card" style={{ width: '100%' }}>
                                                            {membre.chemin ? 
                                                                <img src={`${process.env.REACT_APP_BACKEND_URL}/uploads/comite/${membre.type}/${membre.chemin}`} className="card-img-top" alt="..." />
                                                            :
                                                                <img src="./festava-live/images/default/default.jpg" className="card-img-top" alt="..." />
                                                            }
                                                            <div className="card-body mt-3" style={{ minHeight: 180 }}>
                                                                <h5 className="card-title">{membre.nom}</h5>
                                                                <p className="card-text" style={{ fontSize: 15 }}>
                                                                    <b>Email</b> : {membre.email ?? 'non renseigné'} <br />
                                                                    <b>Tél</b> : {membre.telephone ?? 'non renseigné'}
                                                                </p>
                                                                <a href={membre.lien} className="btn custom-btn" target="_blank">Contacter</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev" style={{ marginTop: 10 }}>
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>

                                  </div>

                              </div>
                          </div>
                        }

                    </div>

                </div>
            </div>
        </section>

        {/************ Evènements *************/}
        <section className="schedule-section section-padding" id="section_4">
            <div className="container">
                <div className="row">
                    <div className="col"></div>
                    <div className="col-12 pb-2 mb-0">
                        <h2 className="text-white mb-3 text-center"><i className="fas fa-calendar"></i> Evénements</h2>

                        { (evenementsPasses && 0 < evenementsPasses.length) || (evenementsProchains && 0 < evenementsProchains.length) ?
                            <nav className="d-flex justify-content-center pt-1 pb-4 mb-4" style={{ marginTop: 50 }}>
                                <div className="nav nav-tabs align-items-baseline justify-content-center bg-success p-1" id="nav-tab"
                                    role="tablist">
                                    {evenementsPasses && evenementsPasses.length > 0 &&
                                    <button className={!evenementsProchains || evenementsProchains.length === 0 ? 'nav-link active p-2' : 'nav-link p-2'} id="events-passes-tab" data-bs-toggle="tab" style={{ margin: 2, width: '8rem' }}
                                        data-bs-target="#nav-events-passes" type="button" role="tab"
                                        aria-controls="nav-events-passes" aria-selected="false" onClick={() => showHideEvenementsTabs('EvenementsPasses')}>
                                        <h7>Passés</h7>
                                    </button>
                                    }
                                    {evenementsProchains && evenementsProchains.length > 0 &&
                                    <button className="nav-link active p-2" id="events-prochains-tab" data-bs-toggle="tab" style={{ margin: 2, width: '8rem' }}
                                        data-bs-target="#nav-events-prochains" type="button" role="tab"
                                        aria-controls="nav-events-prochains" aria-selected="false" onClick={() => showHideEvenementsTabs('EvenementsProchains')}>
                                        <h7>Prochains</h7>
                                    </button>
                                    }                                
                                </div>
                            </nav> : <></>
                        }

                        {evenementsPasses && 0 === evenementsPasses.length && evenementsProchains && 0 === evenementsProchains.length && <h5 className="text-center text-white" style={{ marginTop: '16%' }}>Aucun événement enregistré pour le moment !</h5>}

                        {evenementsPassesTab && 
                            <div className="accordion accordion-flush mt-5" id="accordionFlushExample">
                                {evenementsPasses && evenementsPasses.map((evenementPasse, index) => (
                                    <div className="accordion-item" style={{ border: '2px solid #A2C523' }}>
                                        <h2 className="accordion-header" id={`flush-headingTwo${index}`}>
                                            <button class="accordion-button collapsed text-white" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapseTwo${index}`}  aria-expanded="false" aria-controls={`flush-collapseTwo${index}`} style={{ backgroundColor: '#7D4427' }}>
                                                <div class="container">
                                                    <div class="row">
                                                        <div class="col-md-4 p-1" style={{ color: '#A2C523' }}>
                                                            <b>{evenementPasse.titre}</b>
                                                        </div>
                                                        <div class="col-md-4 p-1 text-center">
                                                            { (new Date(evenementPasse.date.date)).toLocaleDateString('fr-FR', optionsDate) } <small className="">{ formatEventTimes(evenementPasse.heure_debut?.date, evenementPasse.heure_fin?.date) }</small>
                                                        </div>
                                                        <div class="col-md-4 p-1 text-end">
                                                            <span class={`badge ${getCategorieSeverity(evenementPasse.category)}`}>{ evenementPasse.category ? evenementPasse.category : '-' }</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </button>
                                        </h2>
                                        <div id={`flush-collapseTwo${index}`} class="accordion-collapse collapse" aria-labelledby={`flush-headingTwo${index}`} data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body">
                                                <p className="dynamicHtml" dangerouslySetInnerHTML={{ __html: evenementPasse.descriptif }} />
                                                <b className="text-center">Organisé par</b> : {evenementPasse.organisateur} <br />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }

                        {evenementsProchainsTab &&
                            <div className="accordion accordion-flush mt-5" id="accordionFlushExample">
                                {evenementsProchains && evenementsProchains.map((evenementsProchain, index) => (
                                    <div className="accordion-item" style={{ border: '2px solid #A2C523' }}>
                                        <h2 className="accordion-header" id={`flush-headingTwo${index}`}>
                                            <button class="accordion-button collapsed text-white" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapseTwo${index}`}  aria-expanded="false" aria-controls={`flush-collapseTwo${index}`} style={{ backgroundColor: '#7D4427' }}>
                                                <div class="container">
                                                    <div class="row">
                                                        <div class="col-md-4 p-1" style={{ color: '#A2C523' }}>
                                                            <b>{evenementsProchain.titre}</b>
                                                        </div>
                                                        <div class="col-md-4 p-1 text-center">
                                                            { (new Date(evenementsProchain.date.date)).toLocaleDateString('fr-FR', optionsDate) } <small className="">{ formatEventTimes(evenementsProchain.heure_debut?.date, evenementsProchain.heure_fin?.date) }</small>
                                                        </div>
                                                        <div class="col-md-4 p-1 text-end">
                                                            <span class={`badge ${getCategorieSeverity(evenementsProchain.category)}`}>{ evenementsProchain.category ? evenementsProchain.category : '-' }</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </button>
                                        </h2>
                                        <div id={`flush-collapseTwo${index}`} class="accordion-collapse collapse" aria-labelledby={`flush-headingTwo${index}`} data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body">
                                                <p className="dynamicHtml" dangerouslySetInnerHTML={{ __html: evenementsProchain.descriptif }} />
                                                <b className="text-center">Organisé par</b> : {evenementsProchain.organisateur} <br />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                    <div className="col"></div>
                </div>
            </div>
        </section>
        
        {/************ Disciplines *************/}
        <section className="artists-section section-padding" id="section_3">
            <div className="container">
                <div className="row justify-content-center">

                    <div className="col-12 text-center">
                        <h2 className="mb-4"><i className="fas fa-horse"></i> Disciplines</h2>
                    </div>

                    {disciplines.map((discipline, index) => (
                        index <= 3 &&
                        <div className="col-lg-6 col-12">
                            <div className="artists-thumb">
                                <h5 className="text-center">{discipline.titre}</h5>

                                <div className="artists-image-wrap">
                                    <img src={`${process.env.REACT_APP_BACKEND_URL}/uploads/photos/Disciplines/${discipline.chemin}`} className="artists-image img-fluid" style={{ height: 500 }} />
                                </div>

                                <div className="artists-hover pb-3 pt-3">
                                    <p className="mb-1 dynamicHtml" dangerouslySetInnerHTML={{ __html: discipline.description }} />

                                    {discipline.plus && 
                                        <p className="m-0 text-end mt-2 mb-0">
                                            <button className="custom-btn" onClick={() => handleShow(`${discipline.titre}`, `${discipline.plus}`)}>En savoir plus</button>
                                        </p>
                                    }
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </section>
              
        {/************ Médias *************/}
        <section className="pricing-section section-padding section-bg" id="section_5">
            <div className="container">
                <div className="row pb-2">

                    <div className="col-lg-8 col-12 mx-auto">
                        <h2 className="text-center mb-4"><i className="fas fa-image"></i> Photos</h2>
                        <nav className="d-flex justify-content-center mb-3">
                            <div className="nav nav-tabs align-items-baseline justify-content-center bg-success p-1" id="nav-tab"
                                role="tablist">
                                {courses.length > 0 &&
                                  <button className={`nav-link ${activeCourses} p-2`} id="courses-tab" data-bs-toggle="tab" style={{ margin: 2 }}
                                      data-bs-target="#nav-courses" type="button" role="tab"
                                      aria-controls="nav-courses" aria-selected="false" onClick={() => showHidePhotosTabs('Courses')}>
                                      <h7>Courses</h7>
                                  </button>
                                }

                                {animations.length > 0 &&
                                  <button className={`nav-link ${activeAnimations} p-2`} id="animations-tab" data-bs-toggle="tab" style={{ margin: 2 }}
                                      data-bs-target="#nav-animations" type="button" role="tab"
                                      aria-controls="nav-animations" aria-selected="false" onClick={() => showHidePhotosTabs('Animations')}>
                                      <h7>Animations</h7>
                                  </button>
                                }

                                {expositions.length > 0 &&
                                  <button className={`nav-link ${activeExpositions} p-2`} id="expositions-tab" data-bs-toggle="tab" style={{ margin: 2 }}
                                      data-bs-target="#nav-expositions" type="button" role="tab"
                                      aria-controls="nav-expositions" aria-selected="false" onClick={() => showHidePhotosTabs('Expositions')}>
                                      <h7>Expositions</h7>
                                  </button>
                                }

                                {restauration.length > 0 &&
                                  <button className={`nav-link ${activeRestauration} p-2`} id="restauration-tab" data-bs-toggle="tab" style={{ margin: 2 }}
                                      data-bs-target="#nav-restauration" type="button" role="tab"
                                      aria-controls="nav-restauration" aria-selected="false" onClick={() => showHidePhotosTabs('Restauration')}>
                                      <h7>Restauration</h7>
                                  </button>
                                }

                                {sponsors.length > 0 &&
                                  <button className={`nav-link ${activeSponsors} p-2`} id="sponsors-tab" data-bs-toggle="tab" style={{ margin: 2 }}
                                      data-bs-target="#nav-sponsors" type="button" role="tab"
                                      aria-controls="nav-sponsors" aria-selected="false" onClick={() => showHidePhotosTabs('Sponsors')}>
                                      <h7>Sponsors</h7>
                                  </button>
                                }
                            </div>
                        </nav>
                    </div>

                    {coursesTab && courses.length > 0 &&
                        <div className="row tab-pane fade show active" id="nav-courses" role="tabpanel" aria-labelledby="courses-tab">
                            <div className="col-sm-12 col-xs-12 col-lg-9 mx-auto">
                                <ImageGallery items={courses} showBullets={true} showIndex />
                            </div>
                        </div>
                    }

                    {animationsTab && animations.length > 0 &&
                        <div className="row tab-pane fade show active" id="nav-animations" role="tabpanel" aria-labelledby="animations-tab">
                            <div className="col-sm-12 col-xs-12 col-lg-9 mx-auto">
                                <ImageGallery items={animations} showBullets={true} showIndex />
                            </div>
                        </div>
                    }

                    {expositionsTab && expositions.length > 0 &&
                       <div className="row tab-pane fade show active" id="nav-expositions" role="tabpanel" aria-labelledby="expositions-tab">
                            <div className="col-sm-12 col-xs-12 col-lg-9 mx-auto">
                                <ImageGallery items={expositions} showBullets={true} showIndex />
                            </div>
                      </div>
                    }

                    {restaurationTab && restauration.length > 0 &&
                        <div className="row tab-pane fade show active" id="nav-restauration" role="tabpanel" aria-labelledby="restauration-tab">
                            <div className="col-sm-12 col-xs-12 col-lg-9 mx-auto">
                                <ImageGallery items={restauration} showBullets={true} showIndex />
                            </div>
                        </div>
                    }

                    {sponsorsTab && sponsors.length > 0 &&
                        <div className="row tab-pane fade show active" id="nav-sponsors" role="tabpanel" aria-labelledby="sponsors-tab">
                            <div className="col-sm-12 col-xs-12 col-lg-9 mx-auto">
                                <ImageGallery items={sponsors} showBullets={true} showIndex />
                            </div>
                        </div>
                    }

                    {videos.length > 0 &&
                        <>
                            <h2 className="text-center mb-3 mt-4"><i className="fas fa-film"></i> Vidéos</h2>
                            <div className="col-lg-6 col-12 mx-auto mt-3 video-thumbnail">
                                <div className="about-text-wrap p-2" onClick={showVideosToggler} style={{ cursor: 'pointer' }}>

                                    <div className="about-text-info d-flex p-2" style={{ color: '#7D4427' }}>
                                        <div className="d-flex p-auto">
                                            <i className="fas fa-video" style={{ fontSize: 30, marginLeft: 15, marginRight: -8 }}></i>
                                        </div>
                                        <div className="ms-4">
                                            <h3>Vidéos ({videos.length})</h3>
                                        </div>
                                    </div>

                                    <img src="./festava-live/images/animaux/trolls.jpeg" className="about-image img-fluid bg-success p-1" style={{ height: 400, width: '100%' }} />
                                    {videos.length > 0 && <div className="play-icon">&#9658;</div>}
                                </div>
                            </div>
                        </>
                    }

                </div>
            </div>
        </section>
        
        {/************ Contact *************/}
        <section className="about-section section-padding" id="section_6">
            <div className="container">
                <div className="row">

                    <div className="col-lg-8 col-12 mx-auto mb-5">
                        <h2 className="text-center mb-4 text-white"><i className="fas fa-edit"></i> Contact</h2>

                        <nav className="d-flex justify-content-center pt-3 pb-4 mb-3">
                            <div className="nav nav-tabs align-items-baseline justify-content-center bg-success p-1" id="nav-tab"
                                role="tablist">
                                <button className="nav-link active p-2" id="nav-ContactForm-tab" onClick={() => showNousEcrire()} data-bs-toggle="tab" style={{ margin: 2, width: '8rem' }}
                                    data-bs-target="#nav-ContactForm" type="button" role="tab"
                                    aria-controls="nav-ContactForm" aria-selected="false">
                                    <h7><i className="fas fa-pencil-alt"></i> Nous écrire</h7>
                                </button>

                                <button className="nav-link p-2" id="nav-ContactMap-tab" data-bs-toggle="tab" style={{ margin: 2, width: '8rem' }}
                                    data-bs-target="#nav-ContactMap" type="button" role="tab"
                                    aria-controls="nav-ContactMap" aria-selected="false" onClick={() => showHideContactTabs('NousSituer')}>
                                    <h7><i className="fas fa-street-view"></i> Nous situer</h7>
                                </button>
                            </div>
                        </nav>

                        <div className="tab-content shadow-lg mt-5" id="nav-tabContent">
                            { nousEcrireTab && 
                              <div className="tab-pane fade show active" id="nav-ContactForm" role="tabpanel"
                                  aria-labelledby="nav-ContactForm-tab">
                                  <form className="custom-form contact-form mb-5 mb-lg-0" action="#" method="post" role="form">
                                      <div className="contact-form-body">
                                          <div className="row">
                                              <div className="col-lg-6 col-md-6 col-12">
                                                  <input type="text" name="contact-name" id="contact-name" className="form-control" placeholder="Nom complet" required />
                                              </div>

                                              <div className="col-lg-6 col-md-6 col-12">
                                                  <input type="email" name="contact-email" id="contact-email"
                                                      pattern="[^ @]*@[^ @]*" className="form-control"
                                                      placeholder="Adresse e-mail" required />
                                              </div>
                                          </div>

                                          <input type="text" name="contact-company" id="contact-company"
                                              className="form-control" placeholder="Entreprise / Association" required />

                                          <textarea name="contact-message" rows="5" className="form-control" required
                                              id="contact-message" placeholder="Message"></textarea>

                                          <div className="col-lg-4 col-md-10 col-8 mx-auto">
                                              <button type="submit" className="form-control custom-btn"><i className="fas fa-paper-plane"></i> Envoyer</button>
                                          </div>
                                      </div>
                                  </form>
                              </div>
                            }

                            { nousSituerTab && 
                              <div className="tab-pane fade show active" id="nav-ContactMap" role="tabpanel"
                                  aria-labelledby="nav-ContactMap-tab">
                                <div style={{ width: '100%', height: 370 }} >
                                    {loadingContactMap ?
                                        <p style={{ textAlign: 'center', paddingTop: '20%' }}>
                                            Chargement de la carte ...
                                        </p>
                                    :
                                    <iframe width="100%" height="370" style={{ padding: 0 }} src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=23%20rue%20de%20la%20grande%20maison%2044119,%20Treilli%C3%A8res+(F%C3%89D%C3%89RATION%20D%C3%89PARTEMENTAL%20DES%20COURSES%20HIPPIQUES%20RURALES)&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/sport-gps/">hiking gps</a></iframe>
                                    }
                                </div>
                              </div>
                            }
                        </div>

                    </div>

                </div>
            </div>
        </section>

        {returnTop && (
          <button onClick={scrollToTop} className="go-to-top" title="Vers le haut">
            <i className="fas fa-angle-up"></i>
          </button>
        )}

      </main>

      <Footer />
    </>
  );
}

export default WebApp;
