import React, { useEffect, useRef } from "react";

const BackgroundMusic = () => {
  const audioRef = useRef(null);

  useEffect(() => {
    audioRef.current.volume = 0.5;
  });

  return (
    <div>
      <audio
        ref={audioRef}
        src="./festava-live/audio/background.mp3"
        autoPlay
        loop
        muted={false}
      />
    </div>
  );
};

export default BackgroundMusic;
