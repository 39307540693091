export function formatEventTimes(debut, fin) {
  const optionsHeure = { hour: 'numeric', minute: 'numeric' };
  if (!debut) {
    return '';
  }
  const start = new Date(debut).toLocaleTimeString('fr-FR', optionsHeure);
  if (!fin) {
    return `à ${start}`;
  }
  const end = new Date(fin).toLocaleTimeString('fr-FR', optionsHeure);  
  return `de ${start} à ${end}`;
}
